import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './css/ObjectOverview.css';
import ellipse_mini_gray from 'assets/ellipse_mini_gray.svg';
import check_icon from 'assets/icons/check_icon.svg';
import {
  ACTIVE_BROKER,
  ACTIVE_INTERMEDIARY,
  ACTIVE_TOKEN,
  ACTIVE_USER_ID,
  ACTIVE_USER_NAME,
  APPLICANT_DATA,
  BASE_URL_API,
  COLLATERAL_DATA,
  COMPANY_DATA,
  FINANCING_DATA,
  HEADER_AUTHORIZATION,
  KEY_SUCCESS,
  KEY_USER_ID,
  LOAN_PART_DATA,
  NAME_MAX_LENGTH,
  NAME_SUFFIX_DOTS,
  ORIGINAL_SENDER_BROKER,
  ORIGINAL_SENDER_INTERMEDIARY_NUMBER,
  ROLE_ADMIN,
  ROLE_DCMF,
  SEND,
} from 'constants/constants';
import { ApplicantModel } from 'models/applicantModel';
import { useEffect, useState } from 'react';
import { CompanyModel } from 'models/companyModel';
import { CollateralModel } from 'models/collateralModel';
import { FinancingModel } from 'models/financingModel';
import { LoanPartModel } from 'models/loanPartModel';
import { RequestModel } from 'models/requestModel';
import { GetUserIdFromStorage } from 'helpers/storageUtility';
import { DefaultToastContainer } from 'components/toast/toast-container.component';
import { notify } from 'components/toast/notify.component';
import { FormUserModel } from 'models/formUserModel';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { DOSSIER_ERROR, FORM_SEND_FAILED, FORM_SEND_FAILED_RATES_LTV, FORM_SEND_FAILED_RATES_ICR } from 'constants/messages/errorMessages';
import { TITLE_FINANCING } from 'constants/textConstants';
import { calculateInterestValues } from 'helpers/rateCalculations';
import UserDropdown from 'components/UserDropdown';
import { DropdownOptionsModel } from 'models/dropdownOptionsModel';
import { UserModel } from 'models/userModel';
import Header from 'components/header/Header';
import ObjectDataViewRates from 'components/views/ObjectDataViewRates';
import ObjectDataViewSmall from 'components/views/ObjectDataViewSmall';
import { MoonLoader } from 'react-spinners';
import { GetFullNameForPerson } from 'helpers/helpers';
import ObjectDataViewSmallCurrency from 'components/views/ObjectDataViewSmallCurrency';

function ObjectOverview() {
  let navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const [getIsSending, setIsSending] = useState<boolean>(false);

  const [getMaxLtv, setMaxLtv] = useState<Number>(-1);
  const [getMinIcr, setMinIcr] = useState<Number>(-1);
  const [getMinDscr, setMinDscr] = useState<Number>(-1);

  const [userList, setUserList] = useState<DropdownOptionsModel[]>(new Array<DropdownOptionsModel>());
  const [userModels, setUserModels] = useState<UserModel[]>([]);
  const [selectedUser, setSelectedUser] = useState<DropdownOptionsModel>(userList[0]);
  const [selectedOrganisation, setSelectedOrganisation] = useState<string>('');

  const [getLtv, setLtv] = useState<Number>(0);
  const [getIcr, setIcr] = useState<Number>(0);
  const [getDscr, setDscr] = useState<Number>(0);

  useEffect(() => {
    const matchedUser = userModels.find((model) => `${model.firstName} ${model.lastName} - ${model.broker}` === selectedUser.label);
    setSelectedOrganisation(matchedUser?.broker ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  function IsAdmin() {
    const { keycloak } = useKeycloak();

    let roles = keycloak.tokenParsed?.realm_access?.roles;
    let isAdmin = false;

    window.localStorage.setItem(ACTIVE_USER_ID, keycloak.tokenParsed?.sub + '');

    roles?.forEach((role) => {
      if (role === ROLE_ADMIN) {
        window.localStorage.setItem(ACTIVE_TOKEN, keycloak.token!.toString());
        isAdmin = true;
      }
    });

    return isAdmin;
  }

  function HasRole(roleCheck: string): boolean {
    const { keycloak } = useKeycloak();

    let roles = keycloak.tokenParsed?.realm_access?.roles;
    let hasRole = false;

    roles?.forEach((role) => {
      if (role === roleCheck) {
        hasRole = true;
      }
    });

    return hasRole;
  }

  function HandleClickBack() {
    navigate({
      pathname: '/wizard',
      search: '?wizardStep=2',
    });
  }

  function BuildRequest(methodType: string) {
    var myHeaders = new Headers();

    var loanAmount = 0;
    var loanParts = getValueLoanParts;
    for (var i = 0; i < loanParts.length; i++) {
      loanAmount += Number(loanParts[i].loanPartAmount);
    }

    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)
    myHeaders.append('loanData', loanAmount + '');
    if (originalSenderIntermediaryNumber !== null) {
      myHeaders.append('selectedUserData', originalSenderIntermediaryNumber ?? '');
    } else {
      myHeaders.append('selectedUserData', selectedUser?.value ?? '');
    }
    if (originalSenderBroker !== null) {
      myHeaders.append('selectedUserBroker', originalSenderBroker);
    }
    else if (selectedOrganisation !== '' && selectedOrganisation !== undefined)
      myHeaders.append('selectedUserBroker', selectedOrganisation ?? '');
    myHeaders.append(KEY_USER_ID, GetUserIdFromStorage());

    var user = new FormUserModel();
    user.name = localStorage.getItem(ACTIVE_USER_NAME)!;
    user.id = localStorage.getItem(ACTIVE_USER_ID)!;
    user.broker = localStorage.getItem(ACTIVE_BROKER) ?? '';
    user.intermediaryNumber = localStorage.getItem(ACTIVE_INTERMEDIARY) ?? '';

    var requestModel = new RequestModel();
    requestModel.applicants = getValueApplicants;
    requestModel.companies = getValueCompanies;
    requestModel.collaterals = getValueCollaterals;
    requestModel.financing = getValueFinancings;
    requestModel.loanParts = getValueLoanParts;
    requestModel.user = user;

    return {
      method: methodType,
      headers: myHeaders,
      body: JSON.stringify(requestModel),
    };
  }

  function GetValueLoanParts(): string {
    let savedValue = sessionStorage.getItem(LOAN_PART_DATA) || '';
    let loanParts: LoanPartModel[] = [];

    if (savedValue !== '') {
      loanParts = JSON.parse(savedValue);
    }

    if (loanParts && loanParts.length > 0) {
      var totalLoan = 0;
      for (var i = 0; i < loanParts.length; i++) {
        var loan = Number(loanParts[i].loanPartAmount);
        totalLoan += loan;
      }

      var total = totalLoan.toString();
      return total;
    }

    return '0';
  }

  function HandleClickJson() {
    var requestOptions: RequestInit = BuildRequest("POST")

    fetch(BASE_URL_API + 'api/get-form-json', requestOptions as RequestInit).then(
      async (response) => {
        const data = response.text();

        if (!response.ok) {
          return Promise.reject(data);
        }

        var date = new Date();
        var json = (await data).toString();
        var a = document.createElement('a');
        a.href = URL.createObjectURL(new Blob([json]));
        a.download = `aanvraag_${date.getFullYear()}${date.getMonth()}${date.getDay()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}.json`;
        a.click();

        return Promise.resolve();
      },
      (error) => {
        if (error) {
          notify(DOSSIER_ERROR, false);
        }
      }
    );
  }

  function HandleClickSend() {
    var requestOptions: RequestInit = BuildRequest("POST")

    if (getLtv > getMaxLtv) {
      notify(FORM_SEND_FAILED_RATES_LTV, false);
      return;
    }

    if (getIcr < getMinIcr) {
      notify(FORM_SEND_FAILED_RATES_ICR, false);
      return;
    }

    if (!getIsSending) {
      setIsSending(true);

      fetch(BASE_URL_API + 'api/send-form', requestOptions as RequestInit)
        .then(async (response) => {
          const data = response.text;

          if (!response.ok) {
            return Promise.reject(data);
          }
        })
        .then(
          () => {
            setIsSending(false);
            sessionStorage.setItem(KEY_SUCCESS, 'true');
            sessionStorage.clear();
            navigate({ pathname: '/user_dashboard' });
            return Promise.resolve();
          },
          (error) => {
            setIsSending(false);
            if (error) {
              notify(FORM_SEND_FAILED, false);
              console.error(error);
            }
          }
        )
        .finally(() => console.log('sent'));
    }
  }

  let savedValueApplicant = sessionStorage.getItem(APPLICANT_DATA) || '';
  let applicants: ApplicantModel[] = [];
  if (savedValueApplicant !== '') {
    applicants = JSON.parse(savedValueApplicant);
  }

  const [getValueApplicants] = useState<ApplicantModel[]>(applicants);

  //companies
  let savedValueCompany = sessionStorage.getItem(COMPANY_DATA) || '';
  let companies: CompanyModel[] = [];
  if (savedValueCompany !== '') {
    companies = JSON.parse(savedValueCompany);

    for (var i = 0; i < companies.length; i++) {
      companies[i].directors = companies[i].directors.filter((director) => applicants.some((applicant) => applicant.key === director.key));
    }
  }

  const [getValueCompanies] = useState<CompanyModel[]>(companies);

  //collaterals
  let savedValueCollateral = sessionStorage.getItem(COLLATERAL_DATA) || '';
  let collaterals: CollateralModel[] = [];
  if (savedValueCollateral !== '') {
    collaterals = JSON.parse(savedValueCollateral);
  }

  const [getValueCollaterals] = useState<CollateralModel[]>(collaterals);

  //financing
  let savedValueFinancing = sessionStorage.getItem(FINANCING_DATA) || '';
  let financing = new FinancingModel();
  if (savedValueFinancing !== '') {
    financing = JSON.parse(savedValueFinancing);
  }

  const [getValueFinancings] = useState<FinancingModel>(financing);

  //loan parts
  let savedValueLoanParts = sessionStorage.getItem(LOAN_PART_DATA) || '';
  let loanParts: LoanPartModel[] = [];
  if (savedValueLoanParts !== '') {
    loanParts = JSON.parse(savedValueLoanParts);
  }

  const [getValueLoanParts] = useState<LoanPartModel[]>(loanParts);

  //user
  let originalSenderIntermediaryNumber: string | null = sessionStorage.getItem(ORIGINAL_SENDER_INTERMEDIARY_NUMBER) ?? null;
  let originalSenderBroker: string | null = sessionStorage.getItem(ORIGINAL_SENDER_BROKER) ?? null;
  
  var sendButtonClass = getIsSending === true ? 'nav_button_disabled button_next_disabled' : 'nav_button button_next';
  var sendButtonTextClass = getIsSending === true ? 'nav_button_text button_next_text_disabled' : 'nav_button_text button_next_text';

  const getInterest = (): number => {
    return 0;
  };

  const getUpfrontFee = (): number => {
    const upfrontPercentage = Number(loanParts.find((part) => part.hasUpfrontFee)?.upfrontFee) / 100;
    return (Number(GetValueLoanParts()) * upfrontPercentage);
  };

  const hasUpfrontFee = (): boolean => {
    if (loanParts.length === 0) return false;

    return loanParts.some((part) => part.hasUpfrontFee);
  };

  useEffect(() => {
    calculateInterestValues(getValueCollaterals, getValueLoanParts, keycloak.token).then((interestData) => {
      setLtv(interestData.ltv);
      setIcr(interestData.icr);
      setDscr(interestData.dscr);

      setMaxLtv(hasUpfrontFee() ? 75 : interestData.maxLtv);
      setMinIcr(interestData.minIcr);
      setMinDscr(interestData.minDscr);
    });
  }, [getValueCollaterals, getValueLoanParts]);

  function fetchUsers() {
    if (userModels !== undefined && userModels.length > 0) return;
    var requestHeaders = new Headers();
    requestHeaders.append(HEADER_AUTHORIZATION, "Bearer " + keycloak.token)
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: requestHeaders
    };

    fetch(BASE_URL_API + 'api/user-names', requestOptions as RequestInit)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }

        return response.text();
      })
      .then((result) => {
        var parsedResult = JSON.parse(result);
        var models = new Array<UserModel>();

        for (var i = 0; i < parsedResult.length; i++) {
          var currentResult = parsedResult[i];
          var id = currentResult['id'];
          var model = new UserModel(
            id,
            currentResult['firstName'] !== null ? currentResult['firstName'] : currentResult['username'],
            currentResult['lastName'] !== null ? currentResult['lastName'] : '',
            0,
            [],
            [],
            '',
            currentResult['broker'],
            currentResult['intermediaryNumber']
          );

          models.push(model);
        }

        setUserModels(models);
        const userDropdownModels = new Array<DropdownOptionsModel>();
        const personalModel = models.find((model) => model.id === localStorage.getItem('activeUserId'));

        const firstItem = {
          label: 'Mijzelf',
          value: personalModel?.intermediaryNumber ?? '',
          isDisabled: false,
        };

        userDropdownModels.push(firstItem);
        for (var i = 0; i < models.length; i++) {
          const model = models[i];

          if (personalModel?.id === model.id) continue;

          userDropdownModels.push({
            label: `${model.firstName} ${model.lastName} - ${model.broker}`,
            value: model.intermediaryNumber,
            isDisabled: false,
          });
        }

        setUserList(userDropdownModels);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  if (HasRole(ROLE_DCMF) || HasRole(ROLE_ADMIN)) fetchUsers();

  return (
    <div className="app_container">
      <Header />
      {sessionStorage.length !== 0 ? (
        <>
          <div className="title_container">
            <p className="title_object_overview">Controleren en verzenden</p>
          </div>

          <div className="overview_container">
            <div className="data_containers">
              <div className="data_container">
                <div className="scroll_container">
                  {getValueApplicants.map((applicant, index) => {
                    const person = applicant.person;
                    if (person.isRelationPerson) return <div />;

                    const relationPerson =
                      applicant.relationApplicant && applicant.relationApplicant.isAuthorizedSignee
                        ? applicant.relationApplicant.person
                        : undefined;

                    return (
                      <div>
                        <ObjectDataViewSmall
                          index={index}
                          title={GetFullNameForPerson(person)}
                          subtitle={person.isJointlyAndSeverallyLiable === 'Ja' ? applicant.type : 'Betrokkene'}
                          key={applicant.key}
                        />

                        {relationPerson && (
                          <ObjectDataViewSmall
                            index={index}
                            subOption={true}
                            title={GetFullNameForPerson(relationPerson)}
                            subtitle={applicant.type}
                            key={relationPerson.key}
                          />
                        )}
                      </div>
                    );
                  })}
                  {getValueCompanies.map(({ name, key }: any, index) => {
                    var company = getValueCompanies[index];
                    return (
                      <ObjectDataViewSmall
                        index={index}
                        title={name.length > NAME_MAX_LENGTH ? name.substring(0, NAME_MAX_LENGTH) + NAME_SUFFIX_DOTS : name}
                        subtitle={company.companyType.label}
                        key={key}
                      />
                    );
                  })}
                </div>

                {GetDataContainerFooter('Aanvrager(s)')}
              </div>
              <div className="data_container">
                <div className="scroll_container">
                  {getValueCollaterals.map(({ city, streetName, houseNumber, addition, key }: any, index) => {
                    return (
                      <ObjectDataViewSmall
                        index={index}
                        title={getValueCollaterals[index].typeCollateral.label}
                        subtitle={`${streetName} ${houseNumber}${addition} ${city}`}
                        key={key}
                      />
                    );
                  })}
                </div>
                {GetDataContainerFooter('Onderpand(en)')}
              </div>
              <div className="data_container">
                <div className="scroll_container">
                  <ObjectDataViewSmallCurrency
                    index={1}
                    title={'Gewenste financiering'}
                    subtitle={GetValueLoanParts()}
                    key={'KeyForFinancing'}
                  />
                  {getValueLoanParts.map(({ loanPartAmount, key }: any, index) => {
                    return (
                      <ObjectDataViewSmallCurrency
                        index={index}
                        title={loanPartAmount}
                        subtitle={`${getValueLoanParts[index].redemptionType.label}`}
                        key={key}
                      />
                    );
                  })}
                  <ObjectDataViewRates
                    ltv={getLtv}
                    icr={getIcr}
                    dscr={getDscr}
                    maxLtv={getMaxLtv}
                    minIcr={getMinIcr}
                    minDscr={getMinDscr}
                    upfrontFee={getUpfrontFee()}
                    interest={getInterest()}
                  />
                </div>
                {GetDataContainerFooter(TITLE_FINANCING)}
              </div>
            </div>

            <div className="bottom_container">
              <div className="flex_spacer">
                {(HasRole(ROLE_DCMF) || HasRole(ROLE_ADMIN)) && originalSenderIntermediaryNumber === null ? (
                  <UserDropdown
                    title={'Verzenden als'}
                    dropdownId={'user_dropdown_id'}
                    inputClass={'dropdown_field'}
                    options={userList}
                    containerClass={'user_dropdown'}
                    initialValue={userList[0]}
                    getValue={selectedUser}
                    setValue={setSelectedUser}
                  />
                ) : (
                  <div />
                )}
              </div>
              <div className="nav_button_container">
                <div className="nav_button button_back" onClick={() => HandleClickBack()}>
                  <p className="nav_button_text button_back_text">Terug</p>
                </div>
                {!IsAdmin() ? (
                  <div></div>
                ) : (
                  <div className="nav_button button_back" onClick={() => HandleClickJson()}>
                    <p className="nav_button_text button_back_text">Json</p>
                  </div>
                )}

                <div className={sendButtonClass} onClick={() => HandleClickSend()}>
                  <p className={sendButtonTextClass}>{SEND}</p>
                </div>
              </div>
              <div className="flex_spacer" />
            </div>
          </div>
        </>
      ) : (
        <div className="loader">
          <MoonLoader color="#FF7700" />
          {navigate({
            pathname: '/wizard',
          })}
        </div>
      )}

      <DefaultToastContainer />

      <div className="wizard_overview_progress_bar" />
    </div>
  );
}

const GetDataContainerFooter = (text: string) => {
  return (
    <div className="text_holder">
      <div className="check_holder">
        <img src={ellipse_mini_gray} className="check_background" alt="" />
        <img src={check_icon} className="check_icon" alt="" />
      </div>
      <p className="check_text">{text}</p>
    </div>
  );
};

export default ObjectOverview;
